// ugh, do we really have to import all this CSS just for the login & etc pages?
import '../../css/main.scss';
import '../../css/unauthorized.css';
import $ from 'jquery';

$(document).ready(function() {
    $('.add-another-collection-widget').click(function() {
        const list = $($(this).attr('data-list-selector'));
        let counter = list.children().length,
            newWidget = list.attr('data-prototype');
        $(this).text('Add another location');
        newWidget = newWidget.replace(/__name__/g, counter);

        const $widget = $(
            '<div class="field has-addons">' +
                newWidget +
                '<div class="control">' +
                '<a class="button remove-location"><i class="mdi mdi-trash-can"></i></a>' +
                '</div>' +
                '</div>'
        );

        // make input full-width.
        $widget.find('.control:first').addClass('is-expanded');

        counter++;
        list.data('widget-counter', counter);
        let newElem = $(list.attr('data-widget-tags')).html($widget);
        newElem.appendTo(list);
    });

    $('.remove-location').click(function() {
        $(this)
            .parent()
            .parent()
            .remove();
    });

    $('#multi-location').click(function() {
        $('.location-sign-up-form').toggle();
    });

    const $signUp = $('#sign-up');
    $signUp.on('click', '.remove-location', function() {
        $(this)
            .closest('li')
            .remove();
        return false;
    });

    $('#optIn').on('click', function() {
        $('#optInModal').toggleClass('is-active');
        return false;
    });

    $signUp.on('click', '.modal-background', function() {
        $(this)
            .parent()
            .removeClass('is-active');
        return false;
    });

    $('.modal').on('click', '.close', function() {
        $(this)
            .parent()
            .parent()
            .parent()
            .removeClass('is-active');
        return false;
    });

    $signUp.on('click', '.agree', function() {
        $('#tbc_info_optIn').prop('checked', true);
        $('.modal').removeClass('is-active');
        return false;
    });
});
